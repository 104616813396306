
import { Options, Vue } from 'vue-class-component';
import { ExperienceAffinityClient, ExperienceClient } from '@/services/Services';
import Uncompletedprofile from '../components/uncompletedprofile.vue';
import notificationIcon from '../components/notificationIcon.vue';
import ExperienceSettingsModal from '../modals/experience/experienceSettingsModal.vue';
import CategoriesFilterModal from '../modals/experience/categoriesFilterModal.vue';
import OrderByFilterModal from '../modals/experience/orderByFilterModal.vue';
import PeriodFilterModal from '../modals/experience/periodFilterModal.vue';
import ShowExperiencePeriodSelected from '../components/experience/showExperiencePeriodSelected.vue';
import CardShadowInfo from '../components/experience/cardShadowInfo.vue';
import bodymovin from 'lottie-web';
import * as OM from '@/Model';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';

@Options({
    components: {
        Uncompletedprofile,
        notificationIcon,
        ShowExperiencePeriodSelected,
        CardShadowInfo
    }
})
export default class Experience extends Vue {

    categories: OM.ExperienceCategory[] = [];
    searchModel: OM.ExperienceSearchVM = new OM.ExperienceSearchVM();
    experienceList: OM.ExperienceDetailVM[] = [];
    experienceManagementVM: OM.ExperienceManagementVM = new OM.ExperienceManagementVM();
    notifyCount: number = 0;

    hideWelcomeToExperienceBanner: boolean = StorageServices.getHideWelcomeToExperienceBanner();

    created() {
        this.init();
    }

    init() {
        Promise.all([
            ExperienceClient.getAllExperienceCategories(false),
            ExperienceClient.experienceList(this.searchModel),
            ExperienceAffinityClient.getExperienceManagement()
        ])
        .then(xs => {
            this.categories = xs[0];
            this.experienceList = xs[1];
            this.experienceManagementVM = xs[2];
        })
    }

    mounted(){
        setTimeout(() => {
            var welcomeExperience = bodymovin.loadAnimation({
                container: <any>this.$refs.welcomeExperience,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            welcomeExperience.play();
        }, 300);
    }

    openOrderByFilter() {
        this.$opModal.show(OrderByFilterModal, {
            orderByAscending: this.searchModel.orderByAscending,
            callback: (newValue: boolean) => {
                this.searchModel.orderByAscending = newValue;
                this.$opModal.closeLast();
                this.init();
            }
        })
    }
    openCategoriesFilter() {
        this.$opModal.show(CategoriesFilterModal, {
            categoriesSelectedIds: this.searchModel.categoriesIdentifiers,
            categories: this.categories,
            callback: () => {
                this.$opModal.closeLast();
                this.init();
            }
        })
    }
    openPeriodFilter() {
        this.$opModal.show(PeriodFilterModal, {
            periodSelected: this.searchModel.title,
            callback: (newValue: string) => {
                this.searchModel.title = newValue;
                this.$opModal.closeLast();
                this.init();
            }
        })
    }

    openSettings() {
        this.$opModal.show(ExperienceSettingsModal, {
            notifyCount: this.notifyCount,
            categories: this.categories,
            experienceManagementVM: this.experienceManagementVM
        })
    }

    hideWelcomeBanner() {
        this.hideWelcomeToExperienceBanner = true;
        StorageServices.setHideWelcomeToExperienceBanner(true);
    }

}
