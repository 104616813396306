import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_experience_categories = _resolveComponent("experience-categories")
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: _imports_0,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$opModal.closeLast()))
      })
    ]),
    _createVNode(_component_experience_categories, {
      title: _ctx.$localizationService.getLocalizedValue('app_RestaSempreAggiornatoSulleNuoveExperience', 'Resta sempre aggiornato sulle nuove Experience create dalla community.<br />Seleziona le categorie dai cui ricevere una notifica:'),
      categoriesSelectedIds: _ctx.settings.experienceCategoriesNotification,
      categories: _ctx.categories
    }, null, 8, ["title", "categoriesSelectedIds", "categories"]),
    _createVNode("button", {
      class: "fev_button big transparent bg_brand bg_on_text mt-4",
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.save()))
    }, [
      _createVNode(_component_localized_text, {
        localizedKey: "app_Salva",
        text: "Salva"
      })
    ])
  ]))
}