
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { AppUserClient } from '@/services/Services';
import ExperienceCategories from '../../components/experience/experienceCategories.vue';
import * as OM from '@/Model';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
        ExperienceCategories
    }
})
export default class CategoriesNotificationFilterModal extends Vue {

    @Prop({
        default: () => []
    }) categories: OM.ExperienceCategory[];

    settings: OM.UpdateSettingsVM = new OM.UpdateSettingsVM();

    created() {
        AppUserClient.getProfileEdit()
        .then(x => {
            this.settings = {
                gender: x.profileData.gender,
                showAge: x.profileData.showAge,
                emailAddress: x.emailAddress,
                mailing: x.mailNotification,
                notification: x.smsNotification,
                showSexualOrientation: x.profileData.showSexualOrientation,
                sexualOrientation: x.profileData.sexualOrientation,
                experienceCategoriesNotification: x.experienceCategoriesNotificationIds
            };
            
            if(!this.settings.experienceCategoriesNotification)
                this.settings.experienceCategoriesNotification = [];
        })
    }

    save() {
        AppUserClient.updateSettings(this.settings)
        .then(x => {
            this.$opModal.closeLast();
        }).catch(err => {
            this.$opModal.show(InfoModal, {
                img: "face_error.svg",
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Ok", "Ok"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

}
