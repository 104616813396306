
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import * as OM from '@/Model';

@Options({
    components: {
    }
})
export default class ExperienceCategories extends Vue {

    @Prop({
        default: ""
    }) title: string;
    localTitle: string = "";

    @Prop({
        default: () => []
    }) categoriesSelectedIds: string[];

    @Prop({
        default: () => []
    }) categories: OM.ExperienceCategory[];

    created() {
        if(!this.title)
            this.localTitle = this.$localizationService.getLocalizedValue("app_ScegliLaCategoria", "Scegli la categoria:");
        else
            this.localTitle = this.title;
    }

    toggle(categoryId: string) {
        var findIndex = this.categoriesSelectedIds.indexOf(categoryId);
        if(findIndex == -1)
            this.categoriesSelectedIds.push(categoryId);
        else
            this.categoriesSelectedIds.splice(findIndex, 1);
    }

    checkIfActive(categoryId: string) {
        return this.categoriesSelectedIds.indexOf(categoryId) > -1;
    }

    resetSelectedList() {
        var length = this.categoriesSelectedIds.length;
        this.categoriesSelectedIds.splice(0, length);
    }
    
}
