import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "my-4" }
const _hoisted_3 = { class: "d-flex align-items-center justify-content-center flex-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: _imports_0,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callback(_ctx.localOrderByAscending)))
      })
    ]),
    _createVNode(_component_localized_text, {
      class: "fev_text6 fw_700 d-block mb-4",
      localizedKey: "app_OrdinaPer:",
      text: "Ordina per:"
    }),
    _createVNode("div", _hoisted_3, [
      _createVNode("div", {
        class: ["radiusInfo category big mb-3", !_ctx.localOrderByAscending ? 'selected' : 'shadowRadius'],
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.localOrderByAscending = false))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_PiùRecente",
          text: "Più Recente",
          class: "fev_text6 fw_700 nowrap"
        })
      ], 2),
      _createVNode("div", {
        class: ["radiusInfo category big mb-3", _ctx.localOrderByAscending ? 'selected' : 'shadowRadius'],
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.localOrderByAscending = true))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_MenoRecente",
          text: "Meno Recente",
          class: "fev_text6 fw_700 nowrap"
        })
      ], 2)
    ])
  ]))
}