
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
    components: {
    }
})
export default class OrderByFilterModal extends Vue {

    @Prop({
        default: false
    }) orderByAscending: boolean;
    localOrderByAscending: boolean = false;

    @Prop() callback: any;

    created() {
        this.localOrderByAscending = this.orderByAscending;
    }

}
