
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import CategoriesNotificationFilterModal from './categoriesNotificationFilterModal.vue';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class ExperienceSettingsModal extends Vue {

    @Prop({
        default: 0
    }) notifyCount: number;

    @Prop({
        default: () => []
    }) categories: OM.ExperienceCategory[];

    @Prop({
        default: () => new OM.ExperienceManagementVM()
    }) experienceManagementVM: OM.ExperienceManagementVM;

    created() {}

    notifications() {
        this.$opModal.closeLast();
        this.$opModal.show(CategoriesNotificationFilterModal, {
            categories: this.categories
        })
    }

    myExperiences() {
        if(!this.experienceManagementVM.hasAnyExperience)
            return;

        this.$opModal.closeLast();
        this.$router.push('/my-experience-list/');
    }

    myApplications() {
        if(!this.experienceManagementVM.hasAnyApplication)
            return;

        this.$opModal.closeLast();
        this.$router.push('/my-application-list/');
    }

    newExperience() {
        if(!this.experienceManagementVM.canCreateNewExperience)
            return;

        this.$opModal.closeLast();
        this.$router.push('/experience/edit/');
    }

}
