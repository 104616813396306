import { createVNode as _createVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-center flex-wrap" }
const _hoisted_2 = { class: "fev_text6 fw_700 nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("span", {
      class: "fev_text6 fw_700 text-center px-5 pb-1 d-block mb-4",
      innerHTML: _ctx.localTitle
    }, null, 8, ["innerHTML"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: ["radiusInfo category big mb-3", _ctx.categoriesSelectedIds.length == 0 ? 'selected' : 'shadowRadius'],
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.resetSelectedList()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_Tutte",
          text: "Tutte",
          class: "fev_text6 fw_700 nowrap"
        })
      ], 2),
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.categories, (category, index) => {
        return (_openBlock(), _createBlock("div", {
          class: ["radiusInfo category big mb-3", _ctx.checkIfActive(category.identifier) ? 'selected' : 'shadowRadius'],
          onClick: ($event: any) => (_ctx.toggle(category.identifier)),
          key: index
        }, [
          (category.iconUrl)
            ? (_openBlock(), _createBlock("img", {
                key: 0,
                src: category.iconUrl
              }, null, 8, ["src"]))
            : _createCommentVNode("", true),
          _createVNode("span", _hoisted_2, _toDisplayString(_ctx.$localizationService.getTextFromValues(category.title.values)), 1)
        ], 10, ["onClick"]))
      }), 128))
    ])
  ], 64))
}