import { createVNode as _createVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal bg_white pb-4 experienceSettingsModal" }
const _hoisted_2 = { class: "modal_header d-flex justify-content-end" }
const _hoisted_3 = { class: "position-relative" }
const _hoisted_4 = {
  key: 0,
  class: "notificationCircle bg_experience"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: _imports_0,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('close')))
      })
    ]),
    _createVNode("div", null, [
      _createVNode("button", {
        class: "fev_button big transparent white",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.notifications()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_ImpostaNotifiche",
          text: "Imposta notifiche"
        })
      ])
    ]),
    _createVNode("div", _hoisted_3, [
      _createVNode("button", {
        class: ["fev_button big transparent white", { 'disabled' : !_ctx.experienceManagementVM.hasAnyExperience }],
        disabled: !_ctx.experienceManagementVM.hasAnyExperience,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.myExperiences()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_LeMieExperience",
          text: "Le mie experience"
        })
      ], 10, ["disabled"]),
      (_ctx.notifyCount > 0)
        ? (_openBlock(), _createBlock("div", _hoisted_4, _toDisplayString(_ctx.notifyCount), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode("div", null, [
      _createVNode("button", {
        class: ["fev_button big transparent white", { 'disabled' : !_ctx.experienceManagementVM.hasAnyApplication }],
        disabled: !_ctx.experienceManagementVM.hasAnyApplication,
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.myApplications()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_LeMieCandidature",
          text: "Le mie candidature"
        })
      ], 10, ["disabled"])
    ]),
    _createVNode("div", null, [
      _createVNode("button", {
        class: ["fev_button big transparent white", { 'disabled' : !_ctx.experienceManagementVM.canCreateNewExperience }],
        disabled: !_ctx.experienceManagementVM.canCreateNewExperience,
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.newExperience()))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "app_CreaNuova",
          text: "Crea nuova"
        })
      ], 10, ["disabled"])
    ])
  ]))
}