import { createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/close_x_blue.svg'


const _hoisted_1 = { class: "modal bg_white flex_centered column" }
const _hoisted_2 = { class: "my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_experience_categories = _resolveComponent("experience-categories")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("img", {
        src: _imports_0,
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callback()))
      })
    ]),
    _createVNode(_component_experience_categories, {
      title: '',
      categoriesSelectedIds: _ctx.categoriesSelectedIds,
      categories: _ctx.categories
    }, null, 8, ["categoriesSelectedIds", "categories"])
  ]))
}