
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ExperienceCategories from '../../components/experience/experienceCategories.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ExperienceCategories
    }
})
export default class CategoriesFilterModal extends Vue {

    @Prop({
        default: () => []
    }) categoriesSelectedIds: string[];

    @Prop({
        default: () => []
    }) categories: OM.ExperienceCategory[];

    @Prop() callback: any;

    created() {}

}
