
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ExperiencePeriodVM } from '@/viewModel';

@Options({
    components: {
    }
})
export default class PeriodFilterModal extends Vue {

    @Prop({
        default: ""
    }) periodSelected: string;
    localPeriodSelected: string = "";

    @Prop() callback: any;

    created() {
        this.localPeriodSelected = this.periodSelected;
    }

    get isWeekendActive() {
        return this.localPeriodSelected == ExperiencePeriodVM.weekend;
    }
    get isWeekActive() {
        return this.localPeriodSelected == ExperiencePeriodVM.week;
    }
    get isMonthActive() {
        return this.localPeriodSelected == ExperiencePeriodVM.month;
    }
    setWeekendActive() {
        this.localPeriodSelected = ExperiencePeriodVM.weekend;
    }
    setWeekActive() {
        this.localPeriodSelected = ExperiencePeriodVM.week;
    }
    setMonthActive() {
        this.localPeriodSelected = ExperiencePeriodVM.month;
    }
    setEmpty() {
        this.localPeriodSelected = "";
    }

}
